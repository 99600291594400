<template>
    <div>
        <div id="gag">
            <div id="target" v-on:click="gag()"></div>
        </div>
        <div id="splash" v-on:click="goToHome()" >
            <div class="container">
                <div class="content content-large">
                    <div class="text">
                        <div class="gauche">
                            <div class="txt1">FESTIVAL<br/>DU<br/>FILM</div>
                            <div class="txt2">DE<br/>METZ</div>
                            <div class="txt3">SUBVERSIF, IVE</div>
                            <div class="txt4">[sybvεRsif, iv] adj.<br/>qui bouscule les codes<br/>et les normes établies</div>
                        </div>
                        <div class="droite">
                            <img class="logovert" alt="Logo du festival subversif" src="./../assets/vert.png">
                            <div class="txt6">7ème EDITION</div>
                            <div class="txt7">DU 10 AU 12<br/>JUIN 2022</div>
                        </div>
                    </div>
                    <div class="image">
                        <img class="tigre" alt="Logo du festival subversif" src="./../assets/tigre.png">                    
                    </div>
                </div>
                <div class="content content-small">
                    <div class="text">
                        <div class="txt6">7ème EDITION</div>
                        <div class="txt1">FESTIVAL<br/>DU<br/>FILM</div>
                        <img class="logovert" alt="Logo du festival subversif" src="./../assets/vert.png">
                        <div class="txt2">DE<br/>METZ</div>
                        <div class="txt3">SUBVERSIF, IVE</div>
                        <div class="txt4">[sybvεRsif, iv] adj.<br/>qui bouscule les codes<br/>et les normes établies</div>
                        <div class="txt7">DU 10 AU 12<br/>JUIN 2022</div>
                    </div>
                    <div class="image">                    
                        <img class="tigre" alt="Logo du festival subversif" src="./../assets/tigre.png">
                    </div>
                    
                </div>
                <div class="bottom">
                    <div id="showme">ENTRER</div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {    
    name: 'splashscreen',
    title:'Festival Subversif',
    data(){
        return{
            tigreHeight:70,
            nbClick:0,
        }        
    },
    components:{
        
    },
    mounted(){
        this.nbClick = 0;
        document.body.classList.add('bg-light');
    },
    methods:{
        goToHome(){
           this.$router.push('festival');
        },
        gag(){
            this.nbClick++;
            if(this.nbClick < 3){
                this.tigreHeight = this.tigreHeight + 15;
                document.getElementsByClassName('tigre')[0].style.height = this.tigreHeight+'vh';
                document.getElementsByClassName('tigre')[1].style.height = this.tigreHeight+'vh';
            }else{
                setTimeout(function() {
                    document.getElementsByClassName('tigre')[0].classList.add('gobig');
                    document.getElementsByClassName('tigre')[1].classList.add('gobig');
                },0);                
            }
        }
    }
}
</script>

<style>
.bg-light #app{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(248,200,215,1) 100%);
}

</style>

<style scoped lang="scss">

#gag{
    width:100%;
    height:100vh;
    z-index:150;
}

#target{
    position:absolute;
    left: calc(50% - (42vh*0.23)); //0.23 est le ration hauteur / emplacement
    top: calc(50% - (42vh*0.55));
    z-index:150;
    height:10px;
    width:10px;
}

@keyframes animatecta {
  50% {opacity: 0;}
  75% {opacity: 25;}
  100% {opacity: 1;}
}
#showme {
  opacity: 0;
  animation: animatecta 0s 10s forwards;  
  color:#2E2014;
  background-color: rgb(255, 237, 76);
  font-family: 'Bebas Neue';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(255, 237, 76,.7);
  padding: 1em;
  font-size: 2.5em;
}
#showme:hover, #showme:focus, #showme:active {  
  color:rgb(255, 237, 76);
  background-color: #2E2014;
  
}

#splash{
    width:100%;
    position: absolute;
    top:0;
    z-index:50;
    min-height:100vh;
    cursor: pointer;
}

.content{
  font-family: 'Arial';
  color:#2E2014;
  display:flex;
    justify-content:center;
    flex-direction:row;
    z-index: 50;
}
.image{     
    text-align: left;    
    z-index: 50;
}
.logovert{
    height:42vh;// 8vh*5 + 2 de margin 
}

.txt1, .txt2{
    font-size:8vh;
    line-height: 1em;
    font-weight:600;
}
.txt4{
    font-size:2vh;
    line-height: 1em;
}
.txt6{
    font-size:3vh;
}
.txt7{
    font-size:4vh;
    line-height: 1em;
}
.text{
    z-index: 50;
}

.content-large{
    height:100vh;
    align-items: center;

    .tigre{
        height:70vh;
        margin-left:10px;
        z-index:100;
        transition: height .5s ease-in-out;
    
        &.gobig{
            height:260vh !important;
            transition: height 5s ease-in-out;
        }
    }

  
    .text{
        justify-content: end;
        display:flex;
        margin-right:10px;  
        width:50%;
        color:#000;
        flex-direction: row;
    }
    .image{
        width:50%;
    }
    .gauche{
        padding-top:3vh;
        margin-right:10px;
        text-align: right;
    }
    .droite{
        text-align: left;
    }
   
}

.content-small{

    .text{
        width:35%;
        padding-top:30px;
    }
    .image{
        overflow: hidden;        
        width:65%;
    }

    .tigre{
        height:80vh;
        margin-left:10px;
        z-index:100;
        transition: height .5s ease-in-out;
    
        &.gobig{
            height:260vh !important;
            transition: height 5s ease-in-out;
        }
    }

    .logovert{
        height:34vh;
    }
    .txt1, .txt2{
        font-size:6vh;
    }
    .txt3, .txt4{
        font-size:.8em;
    }
    .txt6{
        font-size:3vh;
    }
    .txt3{
        padding-top:2vh;
    }
    .txt4{
        padding-bottom:2vh;
    }
    .txt7{
        font-size:3vh;
    }

}

    .content-large{
        display: flex;
    }
    .content-small{
        display:none;
    }

@include media-breakpoint-down(sm) {
    .content-large{
        display: none;
    }
    .content-small{
        display:flex;
    }
    .container{
        max-width: 100%;
        margin:0;
        padding:0;
    }

    #target{
        left: calc(30% - 10px - (12em*0.31));
        top: calc(30px + 26vh);
    }
}
/*
@include media-breakpoint-down(xs){
    .content-small{
       .text{
        width:50%;
    }
    .image{
        width:50%;
    }
    }
}
*/
.calltoaction button{
    border:0;
    font-family: 'Arial';
    background-color:#2E2014;
    color:#FFED4C;
    padding:5px 15px 2px 15px;
    line-height: 1.5em;
    z-index:25;
    margin-top:20px;
    font-size:2em;

    &:hover, &:active{
        color:#2E2014;
        background-color: #FFED4C;
    }
}



</style>
<template>

  <div>    

    <div id="nav">
        <ul class="tags" >
          <li class="tag" v-on:click="onSelectTag(null)" v-bind:class="{ active: selectedTag == null }" >Tous les articles</li>
          <li> - </li>
          <li class="tag" v-for="tag in tags" v-bind:key="tag.key" v-on:click="onSelectTag(tag.key)" v-bind:class="{ active: tag.key == selectedTag }" >
            {{tag.title}}
            </li>
        </ul>
        <ul>
          <li><a class="tag" href="https://2021.subversif.fr/" title="Archives 2021">Archives 2021</a></li>
          </ul>
    </div>
    
    <div class="cat-title" v-if="displayFeatured">Avant tout</div>
    <div class="featured" v-if="displayFeatured">
      <template v-for="article in featured">
        <div class="grid-item" v-bind:key="article.id" v-on:click="onSelectedArticle(article.id)" v-bind:class="{ 'enter-selected': article.id == selected, gray: selected !== null && article.id !== selected }">
          <p class="title"><span>{{ article.titre}}</span></p>
          <img :src="article.file.thumbnail" width="400px" height="auto"/>            
        </div>
        <div class="detailswrapper" v-bind:key="article.id+'test2'" v-bind:class="{'enter-opened':selected == article.id}" >
          <div class="details">
            <div class="titlebox">
              <div  v-on:click="selected = null" class="closebtn"><font-awesome-icon icon="times" /></div>
            </div>
            <div v-if="!article.isFilm" class="text" v-html="article.texte"></div>
            <div class="supertext" v-if="article.isFilm == true">
              <div class="textleft soustexte" v-html="article.textegauche"></div>
              <div class="textright soustexte" v-html="article.textedroite"></div>
            </div>
            <div v-if="article.gallerie">
              <img class="image" v-for="(image, i) in article.galleriemini" :src="image" @click="openGallery(article, i)" v-bind:key="i" height="auto">
              <vue-gallery-slideshow :id="article.id" :images="article.gallerie" :index="indexes[article.id]" @close="closeGallery(article)" :key="featuredGalleryKey"></vue-gallery-slideshow>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="cat-title">Articles</div>
    <div class="articles">
      <div v-if="loading">Chargement en cours...</div>
      <div v-if="articles.length ==0 && !loading">Aucun article dans cette catégorie pour le moment</div>
      <template v-for="article in articles">        
        <div v-if="!(article.featured && displayFeatured)" class="grid-item" v-bind:key="article.id" v-on:click="onSelectedArticle(article.id)" v-bind:class="{ gray: selected !== null && article.id !== selected, 'enter-selected': article.id == selected, 'catfilm':article.isFilm }" >
          <p class="title"><span>{{ article.titre}}</span></p>
          <img :src="article.file.thumbnail" width="400px" height="auto"/>            
        </div>
        <div v-if="!(article.featured && displayFeatured)" class="detailswrapper" v-bind:key="article.id+'test'" v-bind:class="{'enter-opened':selected == article.id}"  >
          <div class="details">
            <div class="titlebox">
              <div  v-on:click="selected = null" class="closebtn"><font-awesome-icon icon="times" /></div>
            </div>
             <div v-if="!article.isFilm" class="text" v-html="article.texte"></div>
            <div class="supertext" v-if="article.isFilm">
              <div class="textleft soustexte" v-html="article.textegauche"></div>
              <div class="textright soustexte" v-html="article.textedroite"></div>
            </div>            

            <div v-if="article.gallerie">
              <img class="image" v-for="(image, i) in article.galleriemini" :src="image" @click="openGallery(article, i)" v-bind:key="i" height="auto">
              <vue-gallery-slideshow :id="article.id+'gallery'" :images="article.gallerie" :index="indexes[article.id]" @close="closeGallery(article)" :key="galleryKey"></vue-gallery-slideshow>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'home',
  title:'Festival Subversif',
  components:{
    VueGallerySlideshow
  },
  data(){
    return {
      annee:'2022',
      articles: [],
      loading:false,
      featured: [],
      selected:null,
      displayFeatured: true,
      selectedTag: null,
      indexes: {},
      galleryKey:0,
      featuredGalleryKey:100,
      tags:[
        {title:'Billetterie',key:'billeterie'},
        {title:'Actualités',key:'actu'},
        {title:'Documentaires',key:'documentaire'},
        {title:'Films',key:'film'},
        {title:'Compétition',key:'competition'},
        {title:'Évènements',key:'event'},
        {title:'Photos',key:'photos'}
      ],
      client: new DirectusSDK(directusConfig)
    }
  },

  beforeMount() {            
      this.loadArticles();
      this.loadFeatured();
  },

  mounted(){
    document.body.classList.remove('bg-light');
    this.$root.$on('refresh', () => {this.onSelectTag(null)});
  },

  methods:{

    onSelectTag(tag){
      // nettoye l'atricle sélectionné
      this.selected = null;
      if(this.selectedTag == tag){
        // if you click it again, it clears the filters 
        this.selectedTag = null;
        this.displayFeatured = true;
      }else{
        this.displayFeatured = false;
        this.selectedTag = tag;      
      }
      if(tag == null){
        this.displayFeatured = true;
      }
      this.loadArticles();
    },

    onSelectedArticle(id){
      if(this.selected == id){
        this.selected = null;
      }else{
        this.selected = id;
      }
    },

    loadFeatured(){
      const options= {
        fields:'*.*.*',
        sort:'-created_on',
        filter:{featured:1, tag:{'contains':this.annee}}
      }
      Promise.all([
        this.client.getItems('articles',options),
        this.client.getItems('films',options)
      ]).then(([a,f]) => this.onRecieveFeatured([...a.data, ...f.data]));
    },

    loadArticles(){
      
      let filter = {};
      if(this.selectedTag != null){
        filter = {tag:[{'contains':this.selectedTag },{'contains':this.annee }], ...filter};    
      }else{
        filter = {tag:[{'contains':this.annee }], ...filter};
      }
      const options= {
        fields:'*.*.*',
        sort:'-created_on',
        filter:filter        
      };
      this.articles = [];
      this.loading = true;
      Promise.all([
        this.client.getItems('articles',options),
        this.client.getItems('films',options)
        ]
      ).then(([a,f]) => this.onRecieveArticles([...a.data.map(f => {f.isFilm = false; return f}), ...f.data.map(f => {f.isFilm = true; return f})]));
    },

    openGallery(article, index){
      this.indexes[article.id] = index;
      // pour rafraichir la vue
      this.galleryKey++;
      this.featuredGalleryKey++;
    },

    closeGallery(article){
      this.indexes[article.id] = null;
      // pour rafraichir la vue
      this.galleryKey++;
      this.featuredGalleryKey++;
    },

    onRecieveArticles(articles){
      this.loading = false;
      this.articles = articles;
      this.articles.forEach(a => this.indexes[a.id]= null);
      // on récupère l'url de la thumbnail
      this.articles.forEach(a => a.file.thumbnail = a.file.data.thumbnails.filter(i => i.key === "thumbs-crop-400")[0].url);

      // on construit la gallerie
      this.articles.forEach(a => {
        const initial =  [...a.gallerie];
        a.gallerie = initial.map(e =>e.directus_files_id.data.full_url);
        a.gallerieindex=null;
        a.galleriemini = initial.map(e =>e.directus_files_id.data.thumbnails.filter(i => i.key === "thumbs-200")[0].url);
      });
    },

    onRecieveFeatured(featured){
      this.featured = featured;

      // on récupère l'url de la thumbnail
      this.featured.forEach(a => a.file.thumbnail = a.file.data.thumbnails.filter(i => i.key === "thumbs-crop-400")[0].url);
      // je dois récupérer les thumbnails de la gallerie 
      this.featured.forEach(a => {
        const initial =  [...a.gallerie];
        a.gallerie = initial.map(e =>e.directus_files_id.data.full_url);
        a.gallerieindex=null;
        a.galleriemini = initial.map(e =>e.directus_files_id.data.thumbnails.filter(i => i.key === "thumbs-200")[0].url);
      });
    }
  }
}
</script>

<style>
#app{
    background: #000;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@media (min-width: 780px) {
  .vgs__container{
    margin-top: 12vh !important;
  }
}
main.container-fluid{
  padding:0;
}


// la largeur d'une vignette fait 400px; 
// plus 25px de marge de chaque côté
// plus 2px de rab :
// pour mettre N vignette j'ai besoin de  = 450N+2 
// 1 = 452
// 2 = 902
// 3 = 1352
// ...
@media (min-width: 410px) {
  main.container-fluid{
    width:410px;
  }
}

@media (min-width: 818px) {
  main.container-fluid{
    width: 818px; // 2*450 + 2px
  }
}

// 450 * 2 = 900
// 450 * 3 = 1350
@media (min-width: 1226px) {
  main.container-fluid{
		width: 1226px;
  }
}

// 450 * 3 = 1350
// 450 * 4 = 1800
@media (min-width: 1632px) {
  main.container-fluid{
		width: 1632px;
  }
}


// ----------------- Gestion du film

.f-film {
  display: flex;
  flex-wrap: wrap;
  .f-affiche{
    width:300px;
    border:none !important; 
    
    img{
      width:300px;
      height: auto;
    }    
  }

  .f-text{    
    min-width: 300px;
    text-overflow: ellipsis;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif !important;

    .div, .span{
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
  .f-synopsys{
    padding-top:1em;
  }
  .f-description, .f-synopsys {
    border:none !important; 
    font-family: Arial, Helvetica, sans-serif !important;
  }
}

.f-clear{
  clear:both;
}

@media (min-width: 818px) {
  .f-text{
    max-width: calc(100% - 300px);
  }
  .f-description, .f-synopsys {
    padding-left:1em;
  }
}


// -- FIXME PROGRAMMATION
/*
.hscroll {
  overflow-x:scroll;
}

.f-hscroll {
  overflow-x:scroll;
}
*/

</style>
<style scoped lang="scss">

#nav{
  position: sticky;
  top:0;
  z-index: 1000;
  background-color: black;
  padding:1em 0;
}


h3 {
  margin: 40px 0 0;
}

.cat-title{
  text-align: left;
  font-size: 1.6em;
  color: black;
  background-color: white;
  line-height: 1.3em;  
  margin-top:4px;
  margin-left:0;
  margin-right: 0;
  margin-bottom:4px;
  clear:both;
  font-family:'Bebas Neue';
  padding:4px 4px 0px 4px;
}


@media (min-width: 410px) {
  .cat-title{
    margin-right: 4px;
    margin-left: 4px;
  }
}



.articles{
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.grid-item{
  display: inline-block;
  margin: 4px 0;
  padding:0px;
  vertical-align: top;
  width:400px;
  height: 225px;
  position:relative;
  cursor: pointer;
}

.featured {
  text-align: left;
  .grid-item{
    width: 400px;
    height: 225px;
  }
}

.detailswrapper {
  /* Par défaut je suis masqué, ne pas utiliser display:none*/
  max-height:0px;
  transition: max-height 0s ease-out;  
  overflow:hidden;
  float:left;
  width:100%;

  &.enter-opened {
    height:auto;
    // transition de .5 secondes,
    // mais comme on a mis 10*100vh on fait *10
    transition: max-height 10s ease-out;  
    max-height: 1000vh;
    height: 100%;
  }

  .details {
    margin:4px;
    padding-bottom: 1em;
    text-align: left;
    border:8px solid white;
    padding:1em;
    position:relative;

    .titlebox{      
      display:block;
      position: absolute;
      right:23px;
      z-index: 100;
      top:3px;
         
      .closebtn{
        float:right;
        padding:10px;
        font-size:1em;
        cursor: pointer;        

        &:hover{
          color:#FFED4C;
        }
      }
    }
  }
}

.supertext{
  display:flex;
  flex-direction: column;

  .soustexte {
    padding:5px;
  }
}

@media (min-width: 408px) {
  .enter-opened {
    width:408px;
  }
  .grid-item {
    margin:4px;
  }
  .articles{
    text-align: left;
  }
  .cat-title{
    margin-right: 5px;
  }
}



@media (min-width: 818px) {
  .enter-opened {
    // 400 + marges
    // 400 + marges = 4 = 408 
    width: 815px; // 2*408 + 2px
  }

  .cat-title{
    margin-right: 6px;
  }

  .supertext{
    flex-direction: row;
  }
}

// 408 * 2 = 816
// 408 * 3 = 1350
@media (min-width: 1226px) {
  .enter-opened {
		width: 1226px;
  }
  .text{
    max-width: 1140px;
    margin:0 auto 0;
  }  
  .supertext{
    max-width: 1140px;
    margin:0 auto 0;
  }
}

// 450 * 3 = 1350
// 450 * 4 = 1800
@media (min-width: 1802px) {
  .enter-opened {
		width: 1802px;
  }
  .cat-title{
    margin-right: 4px;
  }
}

// 450 * 4 = 1800
// 450 * 5 = 2225
@media (min-width: 1632px) {
  .enter-opened {
		width: 1632px;
  }
}

.gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

li.active, a.tag:active{
  color:#FFED4C;
}

.title span{
  vertical-align:middle;
  display:inline-block;
  line-height: 1em;
}

.title{
  display:none;
  font-size:1.2em;
  font-weight: bold;
  position: absolute;
  text-align: left;
  background-color: rgba(255,255,255,.6);
  color: #555;
  padding:5px;
  top:0;
  width: 400px;
  height: 225px;
  line-height: 1em;
  padding:20px;
}

.featured .title{
  width: 400px;
  height: 225px;
  line-height: 1em;
}

.grid-item:hover .title{
    display:block;
}

.enter-selected .title{
    display:block;
}


.tag {
  cursor: pointer;
  color:white;
}
a.tag{
  margin: 0 10px;
}
.tag:hover, .tag:focus{
  text-decoration: underline;
  color:white;
}

// ----------- Gallerie
.image{
  margin:4px;
  height:200px;
  width:200px;

  &:hover{
    cursor: pointer;
  }
}


@media only screen and (hover: none) and (pointer: coarse){

  // EN MOBILE
  .grid-item .title{
    display: block;
    height: auto;
    bottom: 0;
    top: auto;
    margin:0;
    color:black;
    background-color: rgba(255,255,255,.8);
  }
}



</style>